import { render, staticRenderFns } from "./MobileAppWizardStep.vue?vue&type=template&id=3c0b2bc2&scoped=true"
import script from "./MobileAppWizardStep.vue?vue&type=script&lang=js"
export * from "./MobileAppWizardStep.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0b2bc2",
  null
  
)

export default component.exports