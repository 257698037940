<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout :row="!main" :column="main">
		<w-flex v-if="!main" xs1>
			<w-layout row justify-center align-center fill-height>
				<v-btn v-if="localIndex > 1" shrink :round="false" tile flat color="primary" class="fill-height" @click="previous">
					<v-icon>arrow_back</v-icon>
				</v-btn>
			</w-layout>
		</w-flex>
		<w-flex :xs10="!main" :grow="main">
			<v-stepper v-model="localIndex" class="elevation-0">
				<template v-for="(step, index) in steps">
					<v-stepper-content v-if="localIndex == index + 1" :key="index" :step="index + 1">
						<w-flex v-if="step.title" text-xs-center headline v-html="step.title"></w-flex>
						<w-flex v-if="step.subtitle" text-xs-center mt-2 title v-html="step.subtitle"></w-flex>
						<w-flex v-if="step.href" text-xs-center mt-2 title>
							<a :href="step.href()" target="_blank">{{ step.href() }}</a>
						</w-flex>
						<w-flex v-if="step.valueToCopy && step.valueToCopy().trim().length > 0" mt-2>
							<w-layout row justify-center align-center>
								<w-btn :round="false" outline color="secondary" @click="copyToClipBoard(step.valueToCopy())">
									<w-layout row justify-center align-center>
										<w-flex v-text="step.valueToCopy()"></w-flex>
										<w-icon outline class="ml-2">content_copy</w-icon>
									</w-layout>
								</w-btn>
							</w-layout>
						</w-flex>
						<w-flex v-if="step.component" mt-2>
							<component :is="step.component" v-model="application" />
						</w-flex>
					</v-stepper-content>
				</template>
			</v-stepper>
		</w-flex>
		<w-flex v-if="!main" xs1>
			<w-layout row justify-center align-center fill-height>
				<v-btn v-if="localIndex < steps.length" shrink :round="false" tile flat color="primary" class="fill-height" @click="next">
					<w-layout row justify-center align-center fill-height>
						<v-icon>arrow_forward</v-icon>
					</w-layout>
				</v-btn>
			</w-layout>
		</w-flex>
		<w-flex v-if="main" shrink>
			<w-layout row justify-center align-center>
				<w-btn flat round color="primary" @click="previous">
					<w-layout row justify-center align-center>
						<w-icon class="mr-2">arrow_back</w-icon>
						<w-flex v-t="'actions.go_back'" shrink />
					</w-layout>
				</w-btn>
				<w-btn v-if="localIndex < steps.length" round color="primary" @click="next">
					<w-layout row justify-center align-center>
						<w-icon class="mr-2">skip_next</w-icon>
						<w-flex v-t="'continue'" shrink />
					</w-layout>
				</w-btn>
				<w-btn v-if="localIndex == steps.length" round color="primary" @click="$emit('done')">
					<w-layout row justify-center align-center>
						<w-icon class="mr-2">done</w-icon>
						<w-flex v-t="'actions.confirm'" shrink />
					</w-layout>
				</w-btn>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'MobileAppWizardStep',
	mixins: [SuperAdminModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		},
		steps: {
			required: true,
			type: Array
		},
		main: {
			required: false,
			type: Boolean,
			default: false
		},
		startWithLast: {
			required: false,
			type: Boolean,
			default: false
		},
		stepName: {
			required: false,
			default: "none",
			type: String
		}
	},
	data: function () {
		return {
			keyboardFn: null,
			localIndex: 1
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		validated: function () {
			return this.localIndex == this.steps.length
		}
	},
	watch: {
		localIndex: {
			immediate: true,
			handler: function () {
				this.validate()
			}
		},
		startWithLast: {
			immediate: true,
			handler: function () {
				if (this.startWithLast) {
					this.localIndex = this.steps.length
				} else {
					this.localIndex = this.value?.wizard ? this.value.wizard.step_index : 1
				}
			}
		}
	},
	mounted: function () {
		this.keyboardFn = function (event) {
			switch (event.code.toLowerCase()) {
				case 'arrowleft':
					this.previous()
					break
				case 'arrowright':
				case 'space':
					this.next()
					break
			}
		}.bind(this)
		window.document.addEventListener('keydown', this.keyboardFn)
		this.retrieveLocalIndex()
	},
	destroyed: function () {
		window.document.removeEventListener('keydown', this.keyboardFn)
	},
	methods: {
		copyToClipBoard: function (value) {
			this.appService.copyToClipboard(value)
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('actions.copied'))
		},
		previous: async function () {
			if (this.localIndex > 1) {
				if (this.stepName != "none") {
					this.service.updateMobileAppWizard(this.value.id, {'step_index': this.localIndex - 1}).then(wizard => {
						this.application.wizard = wizard
						this.localIndex--
					})
				} else {
					this.localIndex--
				}

			} else {
				this.$emit('previous')
			}
		},
		next: async function () {
			if (this.localIndex < this.steps.length) {
				if (this.stepName != "none") {
					this.service.updateMobileAppWizard(this.value.id, {'step_index': this.localIndex + 1}).then(wizard => {
						this.application.wizard = wizard
						this.localIndex++
					})
				} else {
					this.localIndex++
				}
			} else if (this.validated) {
				this.$emit('next')
			}
		},
		validate: function () {
			if (this.validated) {
				this.$emit('validated')
			} else {
				this.$emit('unvalidated')
			}
		},
		retrieveLocalIndex: function () {
			if (this.value?.wizard) {
				this.localIndex = this.value.wizard.step_index
			}
		}
	}
}
</script>

<style scoped></style>
